@import "bootstrap/bootstrap";
@import "admin/sb-admin";
@import "font/font-awesome";
@import "datatables/dataTables.bootstrap4";
@import "select2/select2";
@import "admin/languages/languages";

#editable_text_text {
  font-family: Consolas;
}

.select2-container {
  max-width: 100%;
}

/* Navigation
   ---------------------------------------------------- */

.nav-divider {
  height: 0;
  margin: 1rem auto;
  overflow: hidden;
  width: 90%;
  border-top: 1px solid #adb5bd;
}

/* Tables
   ---------------------------------------------------- */

.datatable {

  tbody > tr > td {
    vertical-align: middle;
  }

  .controls {
    width: 1%;
    white-space: nowrap;

    .form-group {
      margin-bottom: 0;
    }
  }
}

/* Buttons
   ---------------------------------------------------- */

.disabled {
  pointer-events: none;
  cursor: default;
}

/* Typography
   ---------------------------------------------------- */

pre {
  margin: 0;
  padding: 0;
}

.gmap-link {
  display: block;
  font-size: 70%;
  margin-top: -13px;
}

/* Modal
   ---------------------------------------------------- */

.modal {
  .modal-header {
    .close {
      &:hover {
        color: $blue;
        cursor: pointer;
      }
    }
  }

  .modal-content {
    border-radius: 0;
  }
}

.modal-xl {
  max-width: 1200px;
}

#remove-modal {
  .modal-body {
    text-align: center;
  }

  .modal-footer {
    cursor: pointer;
  }
}

/* Select 2
   ---------------------------------------------------- */

.select2-container .select2-selection--single {
  height: 38px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 36px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 36px;
}

/* Algolia Autocomplete
   ---------------------------------------------------- */


.algolia-autocomplete {
  width: 100%;
}

.algolia-autocomplete .aa-input, .algolia-autocomplete .aa-hint {
  width: 100%;
}

.algolia-autocomplete .aa-hint {
  color: #999;
}

.algolia-autocomplete .aa-dropdown-menu {
  width: 100%;
  background-color: #fff;
  border: 1px solid #999;
  border-top: none;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion {
  cursor: pointer;
  padding: 5px 4px;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion.aa-cursor {
  background-color: #B2D7FF;
}

.algolia-autocomplete .aa-dropdown-menu .aa-suggestion em {
  font-weight: bold;
  font-style: normal;
}

